<template>
	<div class="row-space-tbf" v-if="loaded">
		<div class="space-left"><div v-if="$resize && $mq.above(780)" class="go-back" @click="$router.push({name: 'announcements'})"><icon-arrow-back /></div></div>
		<div class="content opacity-page">
			<div class="header-form">
				<h1 class="title">{{ announcement.id ? $t('announcements.update') : $t('announcements.create') }}</h1>
				<div class="actions">
					<button class="btn-tbf white only-icon" @click="$router.push({name: 'announcements'})"><icon-close class="icon-close" /></button>
				</div>
			</div>

			<div class="form-group last">
				<div class="data-group">
					<div class="title">{{ $t('announcements.singular') }}</div>
					<div class="description">{{ $t('announcements.details_form') }}</div>
				</div>
				<div class="form">
					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('general.title')}}*</label>
							<div v-if="$v.announcement.title.$error" class="error-msg">{{ $t('validator.required') }}</div>
							<div v-if="errorsBe.title" class="error-msg">{{ errorsBe.title.join(" | ") }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.announcement.title.$error}">
							<div class="icon-left"><icon-announcement /></div>
							<div class="icon-right" v-if="announcement.title" @click.stop="announcement.title = ''"><icon-close class="icon-clear" /></div>
							<input type="text" :placeholder="$t('roles.name-ph')" class="input-text" v-model="announcement.title">
						</div>
					</div>

					<div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : 'w-50']" v-if="$auth.user().rights.admins_crud">
						<div class="label-header">
							<label class="label">{{ $t('announcements.for_all_company') }}</label>
						</div>
						<div class="input-box bor-0">
							<div class="checkbox-list">
								<div class="checkbox-item m-4" @click="for_all = 0" v-bind:class="{active: for_all === 0}">
									<div class="name">{{ $t('general.no') }}</div>
								</div>
								<div class="checkbox-item m-4" @click="for_all = 1" v-bind:class="{active: for_all === 1}">
									<div class="name">{{ $t('general.yes') }}</div>
								</div>
							</div>
						</div>
					</div>

					<div class="input-group" :class="[$resize && $mq.between([1071,1150]) || $mq.below(600) ? 'w-100' : ($auth.user().rights.admins_crud ? 'w-50' : 'w-100'), {disabled: for_all === 1}]">
						<div class="label-header">
							<label class="label">{{ $t('create-user.department') }}</label>
							<div v-if="$v.selectedDepartments.$error" class="error-msg">{{ $t('validator.required') }}</div>
						</div>
						<div class="input-box" v-bind:class="{has_error: $v.selectedDepartments.$error}">
							<div class="icon-left"><icon-department /></div>
							<div class="icon-right" v-if="selectedDepartments.length" @click.stop="selectedDepartments = ''"><icon-close class="icon-clear" /></div>
							<multiselect 
							class="hide-tags multiselect_checkbox"
							v-bind:class="{populate: selectedDepartments.length}"
							v-model="selectedDepartments"
							:options="optionsDepartments"
							:allow-empty="true"
							:show-labels="false"
							:multiple="true" 
							:close-on-select="false"
							:disabled="for_all === 1"
							track-by="id" 
							label="name"
							>
								<template slot="placeholder" slot-scope="props">
									<span class="text">
										{{ $t('create-user.choose-department') }}
									</span>
								</template>
								<template slot="selection" slot-scope="{ values, search, isOpen }">
									<span class="multiselect__single" v-if="values.length == 1 &amp;&amp; !isOpen">{{ values[0].name }}</span>
									<span class="multiselect__single" v-else-if="values.length > 1 &amp;&amp; !isOpen">{{ values.length }} {{ $t('filters.selected') }}</span>
								</template>
								<template slot="option" slot-scope="props">
									<div class="checkbox">
										<div class="checkmark"></div><span class="text">{{ props.option.name }}</span>
									</div>
								</template>
								<template slot="noResult">{{ $t('create-user.no-results') }}</template>
								<template slot="noOptions">{{ $t('create-user.empty-list') }}</template>
							</multiselect>
						</div>
					</div>

					<div class="input-group w-100">
						<div class="label-header">
							<label class="label">{{$t('general.description')}}</label>
						</div>
						<div class="input-box">
							<quill-editor class="editor-tbf h-300" ref="myQuillEditor" v-model="announcement.body" :options="editorOption" />
						</div>
					</div>

					<div class="input-group w-100">
						<div class="add-task add-file">
							<div class="header-input-file">
								<div class="main-input file-group">
									<button class="btn-tbf blue upload-btn" onclick="document.getElementById('otherFile').click();">
										<span class="text">{{ $t('create-user.add-file') }}</span>
									</button>
									<div class="file-name">{{ fileUpload ? fileUpload.name : '' }}</div>
									<input style="display:none" id="otherFile" type="file" v-on:change="onFileChange">
								</div>
								
								<div class="file-actions">
									<div class="actions" v-if="updateFile !== false">
										<button class="btn-tbf white" @click="cancelEdit"><span class="text">{{ $t('general.cancel') }}</span></button>
										<button class="btn-tbf blue" @click="updateFileFunction"><span class="text">{{ $t('general.update') }}</span></button>
									</div>
									<div class="actions" v-else-if="fileUpload">
										<button class="btn-tbf white" @click="clearFile"><span class="text">{{ $t('general.cancel') }}</span></button>
										<button class="btn-tbf blue" @click="addFile"><span class="text">{{ $t('create-user.upload') }}</span></button>
									</div>
								</div>
							</div>
						</div>

						<div v-if="fileError" class="error-msg custom-file-error">{{ fileError }}</div>
					</div>


					<div v-if="savedFiles.length > 0" class="input-group w-100">
						<div class="textarea-selected-files">
							<div v-for="file, index in savedFiles" class="file-item">
								<span class="name">
								 	<icon-file class="icon-file" /> {{ file.name }}
									<button class="remove" @click="deleteFile(file, index)"><icon-close /></button>
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="form-submit crud">
				<div class="error-msg" v-show="error_message != ''">{{error_message}}</div>
				<button class="btn-tbf grey" @click="$router.push({name: 'announcements'})"><span class="text">{{ $t('general.cancel') }}</span></button>
				<button id="buttonCreate" class="btn-tbf blue" @click="saveAction(announcement.id ? 'update' : 'create')">
					<div class="loader"></div>
					<span class="text">{{ announcement.id ? $t('general.update') : $t('general.add')}}</span>
				</button>
			</div>
		</div>
		<div class="space-right"></div>
	</div>
	<loader-users-create  v-else/>
</template>

<script>
	import IconArrowBack from '../../Icons/ArrowLong'
	import IconClose from '../../Icons/Close'
	import IconFile from '../../Icons/File'
	import IconDepartment from '../../Icons/Department'
	import IconAnnouncement from '../../Icons/Announcement'
	import LoaderUsersCreate from '../../PagesLoaders/UsersCreate'
	import { required, requiredIf } from 'vuelidate/lib/validators'

    import Quill from 'quill'
	import ImageUploader from "quill-image-uploader/src/quill.imageUploader.js"
    import "quill-mention";

	Quill.register("modules/imageUploader", ImageUploader);
    
	export default {
		components: {
			IconArrowBack,
			IconClose,
			IconAnnouncement,
			LoaderUsersCreate,
			IconFile,
			IconDepartment
		},
		data() {
			return {
				loaded: false,
				announcement: {
					title: '',
					body: ''
				},
                editorOption: {
                    placeholder: this.$t('announcements.description-ph'),
                    modules:{
						toolbar: [
							[{ 'header': [1, 2, 3, false] }],
							['bold', 'italic', 'underline', 'strike'],
							['blockquote', 'code-block'],
							[{ 'list': 'ordered'}, { 'list': 'bullet' }, { 'align': [] }],
							[{ 'color': [] }, { 'background': [] }],
							['link', 'image', 'video'],
							['clean']
						],
						imageUploader: {
							upload: (file) => {
								return new Promise((resolve, reject) => {
									let objData = new FormData()
									objData.append('file', file)
									objData.append('type', 'announces')

									axios.post(`/instances/${this.$auth.user().instance.id}}/upload-image`, objData)
									.then(({data}) => {
										resolve(data.file_path);
									})
								});
							},
						},
                        mention: {
                            allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
                            mentionDenotationChars: ["@"],
                            source: async (searchTerm, renderList) => {
                                const matchedPeople = await this.suggestPeople(searchTerm);
                                renderList(matchedPeople, searchTerm);
                            },
                            dataAttributes: ['id', 'name']
                        }
					}
                },
				error_message: '',
				errorsBe: {},
                optionsUsers: [],
				updateFile: false,
				savedFiles: [],
				awaitingFilesForRemove: [],
				fileUpload: '',
				fileError: '',
				optionsDepartments: [],
			  	selectedDepartments: [],
				for_all: 0

			};
		},
		async mounted(){
			if(!this.$auth.user().rights.admins_department_responsibles){
				this.$router.push({name: 'forbbiden'})
			}
			
            await this.getFilters();

			if(this.$route.params.id){
				await this.getAnnouncementData()
			}else{
				setTimeout(() => {
					this.loaded = true
					setTimeout(() => {
						$('.opacity-page').addClass('show')
					}, 0)
				}, 0)
			}
		},
		validations: {
			announcement: {
				title: {required}
			},
			selectedDepartments: { required: requiredIf( function () {
				return this.for_all === 0
			})}
		},
		methods: {
            async getFilters(){
				var filtersDB = {
					users: true,
					departments: true,
					manager_departments: this.$auth.user().rights.admins_crud ? 0 : 1
				}

				await axios.get( `instances/${this.$auth.user().instance.id}/filter`, { params: filtersDB })
				.then(({data}) => {
        			this.optionsUsers = data.data.users
        			this.optionsDepartments = data.data.departments
				}).catch(error => {
					if(error.response) {
						if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
                    setTimeout(() => {
                        this.loaded = true
                    }, 0)
				})
			},
			async getAnnouncementData(){
				await axios.get(`/announces/${this.$route.params.id}/show`)
				.then(({data}) => {
					var dataResponse = data.data;
					this.announcement.id = dataResponse.id;
					this.announcement.title = dataResponse.title;
					this.announcement.body = dataResponse.body;

					this.for_all = dataResponse.for_all;

					if(dataResponse.files.length){
						dataResponse.files.map((el) => {
							this.savedFiles.push({name: el.filename, id: el.id})
						})
					}
					
					dataResponse.departments.map((department) => {
						this.selectedDepartments.push(this.optionsDepartments.find(el => el.id == department.id))
					})

				}).catch(error => {
					if(error.response){
						if(error.response.status == 403) {
							this.$router.push({name: 'forbbiden'})
						}else if(error.response.status == 404) {
							this.$router.push({name: 'not-found'})
						} else if(error.response.status == 500) {
							alert(this.$t('error.500'))
						}
					}
				})
				.finally(() => {
					setTimeout(() => {
						this.loaded = true
						setTimeout(() => {
							$('.opacity-page').addClass('show')
						}, 0)
					}, 0)
				})
			},

			saveAction(type){
				var buttonName = `buttonCreate`
				var btnSubmit = document.getElementById(buttonName);
				var btnSubmitLoader = document.querySelector(`#${buttonName} .loader`)
				var btnSubmitText = document.querySelector(`#${buttonName} .text`)
				
				btnSubmit.disabled = true
				btnSubmit.classList.add('loading')
				btnSubmitLoader.classList.add('onProgress')
				btnSubmitText.innerHTML = this.$t('btn-submit.loading')

				this.error_message = '';
				this.$v.$touch()

				if(!this.$v.$invalid){
					var data = new FormData();

					data.append('title', this.announcement.title);
					data.append('body', this.announcement.body);
					data.append('for_all', this.for_all);

					var selectedDepartments = this.selectedDepartments.length ? this.selectedDepartments.map(function(a) {return a.id;}) : ''
					if(selectedDepartments.length) { 
						for( var i = 0; i < selectedDepartments.length; i++ ){
							data.append('departments[' + i + ']', selectedDepartments[i]);
						}
					}

					var tagUserIds = this.getMentionsIds();
					if(tagUserIds.length) { 
						for( var i = 0; i < tagUserIds.length; i++ ){
							data.append('tag_user_ids[' + i + ']', tagUserIds[i]);
						}
					}

					for( var i = 0; i < this.savedFiles.length; i++ ){
						if(this.savedFiles[i]){
							let fileItem = this.savedFiles[i];
							
							if(fileItem.file){ data.append('files[' + i + '][file]', fileItem.file); }
							if(fileItem.id){ data.append('files[' + i + '][id]', fileItem.id); }
						}
					}

					if(this.awaitingFilesForRemove.length){
						for( var i = 0; i < this.awaitingFilesForRemove.length; i++ ){
							let fileRemoveId = this.awaitingFilesForRemove[i];
							data.append('remove_files_ids[' + i + ']', fileRemoveId);
						}
					}

					if(type == 'create'){
						this.createAnnouncement(data, btnSubmit, btnSubmitText, btnSubmitLoader)
					}else if(type == 'update'){
						this.updateAnnouncement(data, btnSubmit, btnSubmitText, btnSubmitLoader)
					}
				}else{
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = type == 'create' ? this.$t('general.add') : this.$t('general.update')
							btnSubmit.disabled = false
						}, 1000)
					}, 300)
				}
			},
			createAnnouncement(data, btnSubmit, btnSubmitText, btnSubmitLoader){
				axios.post('/announces/store', data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'announcements-show', params: {id: data.data}})
						}, 500)
					}, 300)
				}).catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.add')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
			},
			updateAnnouncement(data, btnSubmit, btnSubmitText, btnSubmitLoader){
				data.append('_method', 'PATCH');

				axios.post(`/announces/${this.announcement.id}`, data, { headers: { 'Content-Type': 'application/x-www-form-urlencoded' }})
				.then(({data}) => {
					btnSubmitLoader.classList.add('finish')
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.success')
						btnSubmit.classList.add('completed')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							this.$router.push({name: 'announcements-show', params: {id: data.data}})
						}, 500)
					}, 300)
				})
				.catch(error => {
					btnSubmitLoader.classList.add('finish')
					this.errorsBe = error.response.data.errors
					setTimeout(()=>{
						btnSubmitText.innerHTML = this.$t('btn-submit.error')
						btnSubmit.classList.add('error')
						btnSubmitLoader.classList.remove('onProgress', 'finish')
						btnSubmit.classList.remove('loading')
						setTimeout(()=>{
							btnSubmit.classList.remove('error')
							btnSubmitText.innerHTML = this.$t('general.update')
							btnSubmit.disabled = false
							if(error.response.status == 500){
								this.error_message = this.$t('error.500')
							}
						}, 1000)
					}, 300)
				})
				.finally(() => {
					setTimeout(()=>{
						btnSubmit.disabled = false
					}, 1500)
				})
			},
            async suggestPeople(searchTerm){
                return this.optionsUsers.filter( (user) => user.name.toUpperCase().includes(searchTerm.toUpperCase())).map((el) => { return {id: el.id, value: el.name} });
            },
			onFileChange(e) {
				this.fileError = ''

				var files = e.target.files || e.dataTransfer.files;
				if (!files.length) return;

				this.fileUpload = files[0]
			},
			clearFile(){
				document.getElementById("otherFile").value = "";
				this.fileUpload = ''
			},
			addFile(){
				if(this.fileUpload){
					if(this.fileUpload.size <= 24999999){
						let objectFile = {
							file: this.fileUpload,
							name: this.fileUpload.name
						}

						this.savedFiles.push(objectFile)
						this.clearFile()
					}else {
						this.fileError = this.$t('validator.file_size')
					}
				}
			},
			deleteFile(file, index){
				if(file.id){
					this.awaitingFilesForRemove.push(file.id)
				}
				this.savedFiles.splice(index, 1)
			},
			getMentionsIds() {
				let arrayMentions = [...document.querySelectorAll('.ql-editor [data-id]')];
				let arrayMentionsIds = [];

				if(arrayMentions.length) {
					arrayMentions.map(el => {
						arrayMentionsIds.push(el.attributes['data-id'].value);
					})
				}

				return arrayMentionsIds;
			}
		}
	};
</script>